import state from './moduleRatePlanManagementState.js'
import mutations from './moduleRatePlanManagementMutations.js'
import actions from './moduleRatePlanManagementActions.js'
import getters from './moduleRatePlanManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

