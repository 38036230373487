<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon
        icon="Edit3Icon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
        @click="editRecord"
      />
      <feather-icon
        icon="Trash2Icon"
        svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
        @click="confirmDeleteRecord"
      />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',

  props: ['hotelId'],

  computed: {
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },

    encryptedID () {
      return this.$secure.encrypt(this.params.data.id)
    }
  },

  methods: {
    editRecord () {
      this.$router.push({ name: 'vancynet-rate-plan-edit', params: {id: this.encryptedID}})
    },

    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${this.params.data.name}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("ratePlanManagement/destroyRatePlan", { hotelId: this.hotelId, ratePlanId: this.params.data.id })
                  .then(() => { this.showDeleteSuccess() })
                  // .catch(err => { this.$catchErrorResponse(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Rate Plan Deleted',
        text: 'The selected rate plan was successfully deleted'
      })
    }
  }
}
</script>
